import React from 'react';
import { Container, Col, Row } from 'react-grid-system';
import Location from 'Images/contact/location.svg';
import Hours from 'Images/contact/hours.svg';
import Whatsapp from 'Images/contact/whatsapp.svg';
import Email from 'Images/contact/email.svg';
import './styles.scss';

const Contact = () => {
  return (
    <div className="contact _section--padding" id="contacto">
      <h2 className="_section--title">
        CONTACTO
      </h2>
      <Container>
        <Row>
          <Col sm={12} lg={6}>
            <ul className="contact--details">
              <li><Location className="contact--icon" />Esmeralda S/N, Río Tercero, Córdoba</li>
              <li><Hours className="contact--icon" />Lunes a Viernes de 10hs a 19hs.<br />Sábados de 10hs a 12hs</li>
              <li><Whatsapp className="contact--icon" />3571 660571</li>
              <li><Email className="contact--icon" />info@valledelrio.com.ar</li>
            </ul>
          </Col>
          <Col sm={12} lg={6}>
            {/* TODO: connect with FormSpree */}
            <form action="" className="contact--form">
              <input className="_section--text" placeholder="NOMBRE COMPLETO" type="text" name="fullName"/>
              <input className="_section--text" placeholder="EMAIL" type="text" name="email" />
              <input className="_section--text" placeholder="NÚMERO DE TELÉFONO" type="text" name="phoneNumber" />
              <label>
                <input type="checkbox" name="confirmCommunication" />
                Acepto ser contactado/a por un asesor
              </label>
              <button className="_section--text">Enviar</button>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact;
