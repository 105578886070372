import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Visible } from "react-grid-system";
import Logo from "Images/logo.svg";
import FacebookLogo from "Images/rrss_facebook.svg";
import InstagramLogo from "Images/rrss_instagram.svg";
import WhatsappMobileLogo from "Images/rrss_whatsapp.svg";
import WhatsappLogo from "Images/contact/whatsapp.svg";
import "./styles.scss";

const navLinks = [
  { label: "Ubicación", to: "#ubicacion" },
  { label: "Servicios", to: "#servicios" },
  { label: "Financiación", to: "#financiacion" },
  { label: "Galería", to: "#galeria" },
  { label: "Contacto", to: "#contacto" },
];

const NavLink = ({ text, to }) => (
  <li>
    <Link to={to}>{text}</Link>
  </li>
);

const WHATSAPP_NUMBER = "543571660571";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  return (
    <nav className="main-nav">
      <Container>
        <Row className="main-nav--wrapper">
          <Col xs={6} sm={2}>
            <Link to={"/"} className="logo">
              <Logo />
            </Link>
          </Col>
          <Col xs={6} sm={10} className="main-nav--links">
            <Visible xs sm md>
              <button
                className={`hamburger hamburger--spin ${
                  isActive ? "is-active" : ""
                }`}
                type="button"
                onClick={() => setIsActive(!isActive)}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </Visible>
            <div
              className={`main-nav--submenu ${isActive ? "is-active" : ""}`}
              onClick={() => setIsActive(false)}
            >
              <Visible xs sm md>
                <Logo className="submenu--logo" />
              </Visible>
              <ul className="submenu--internal-links">
                {navLinks.map(({ label = "", to = "#" }) => (
                  <NavLink text={label} to={to} />
                ))}
              </ul>
              <div className="submenu--social-links">
                <a
                  target="_blank"
                  className="social facebook"
                  href="https://www.facebook.com/loteovalledelrio"
                  rel="noreferrer"
                >
                  <FacebookLogo />
                </a>
                <a
                  target="_blank"
                  className="social instagram"
                  href="https://www.instagram.com/loteovalledelrio"
                  rel="noreferrer"
                >
                  <InstagramLogo />
                </a>
                <a
                  target="_blank"
                  className="social whatsapp"
                  href={`https://wa.me/${WHATSAPP_NUMBER}`}
                  rel="noreferrer"
                >
                  <WhatsappLogo /> Enviar Whatsapp
                </a>
                <a
                  target="_blank"
                  className="social whatsapp-mobile"
                  href={`https://wa.me/${WHATSAPP_NUMBER}`}
                  rel="noreferrer"
                >
                  <WhatsappMobileLogo />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </nav>
  );
};

export default Header;
