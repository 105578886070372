import React from 'react';
import './styles.scss';
import { Container, Row, Col } from 'react-grid-system';

const Metric = ({ number, text }) => {
  return (
    <Col className="info--metric" xs={12} sm={6} md={3}>
      <div className="info--metric--number">
        {number}
      </div>
      <div className="info--metric--text">
        {text}
      </div>
    </Col>
  );
}

const Info = () => {
  return (
    <section className="info">
      <Container>
        <Row>
          <Metric
            number="450"
            text="LOTES"
          />
          <Metric
            number={<>300<sub>M2</sub></>}
            text="DESDE"
          />
          <Metric
            number={<>100<sup>%</sup></>}
            text="FINANCIADOS"
          />
          <Metric
            number={<>80<sup>%</sup></>}
            text="DESARROLLADO"
          />
        </Row>
      </Container>
    </section>
  )
}

export default Info
