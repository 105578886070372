import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import './styles.scss';

const Location = () => {
  return (
    <div className="location _section--padding" id="ubicacion">
      <h2 className="_section--title">
        UBICACIÓN
      </h2>
      <Container>
        <Row>
          <Col className="location--map"  xs={12} lg={6}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6878.122471741361!2d-64.11432145361394!3d-32.149522220302664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cd68d07ed17063%3A0x99d457be190d3d92!2sValle%20del%20Rio%20Tercero!5e0!3m2!1sen!2sar!4v1634847109997!5m2!1sen!2sar" width="100%" height="450" style={{ border:0}} allowfullscreen="" loading="lazy"></iframe>
          </Col>
          <Col className="_section--text" xs={12} lg={6}>
            <p>Ubicado a tan solo 5 minutos del centro de la ciudad, <b>VALLE DEL RÍO</b>, es un espacio urbano que integra y recupera los elementos geográficos que definieron nuestro origen.</p>
            <p>Dos bosques nativos sirven como escurrimientos naturales de agua y seran adaptados como espacios de usos múltiples, paseos y recreación. Además, se encuentra en desarrollo, un gran espacio verde a lo largo del loteo, sobre calle Esmeralda, donde instalaremos un corredor de árboles autóctonos y una ciclovía.</p>
            <p>Un lugar donde el aroma de la naturaleza no sólo se percibe en el ambiente, también se siente en el cuerpo.</p>
            {/* TODO: <Image /> */}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Location
