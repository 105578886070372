import React from 'react';
import { Row, Container, Col } from 'react-grid-system';
import './styles.scss';

const Payments = () => {
  return (
    <div className="payments _section--padding" id="financiacion">
      <h2 className="_section--title">
        FINANCIACIÓN PROPIA
      </h2>
      <div className="_section--text">
        <Container>
          <Row>
            <Col xs={12} sm={6}>
              <p>
                El valor de los lotes varía según su ubicación. Una excelente alternativa para comprar tu lote es la posibilidad de acceder a nuestra "FINANCIACIÓN PROPIA" en cuotas fijas y en pesos.
              </p>
            </Col>
            <Col xs={12} sm={6}>
              <p>
                Comunicándose hoy con nosotros para coordinar su visita al lote y pueda percibir la calidez del entorno y el efecto avance de las obras que garantiza el continuo crecimiento de su inversión.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Payments
