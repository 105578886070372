import React from 'react';
import { Container, Row, Col } from 'react-grid-system';

import ValleDelRioLogo from 'Images/valle_del_rio_logo.svg';
import FacebookLogo from 'Images/rrss_facebook.svg';
import InstagramLogo from 'Images/rrss_instagram.svg';
import WhatsappLogo from 'Images/rrss_whatsapp.svg';

import './styles.scss';

const Footer = () => {
  return (
    <div className="footer _section--padding">
      <Container>
        <Row className="footer--columns">
          <Col className="footer--column" sm={3}>
            <div className="footer--us">
              <p>
                © VALLE DEL RIO <br />
                TODOS LOS DERECHOS RESERVADOS <br />
                2021
              </p>
              <div className="footer--social">
                <a href="https://www.facebook.com/loteovalledelrio" target="_blank" rel="noreferrer"><FacebookLogo /></a>
                <a href="https://www.instagram.com/loteovalledelrio" target="_blank" rel="noreferrer"><InstagramLogo /></a>
              </div>
            </div>
          </Col>
          <Col className="footer--column" sm={4} offset={{ sm: 1 }}>
            <ValleDelRioLogo />
          </Col>
          <Col className="footer--column" sm={3} offset={{ sm: 1 }}>
            <div>
              <p>
                ESMERALDA S/N<br />
                RIO TERCERO<br />
                CÓRDOBA
              </p>
              <div className="footer--phone">
                <WhatsappLogo /> 3571 660571
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
