import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { Container, Col, Row } from 'react-grid-system';
import 'react-circular-progressbar/dist/styles.css';
import './styles.scss';
import { StaticImage } from 'gatsby-plugin-image';

import folleto from '../../../assets/folleto_valle_del_rio.pdf';
import masterplan from '../../../assets/masterplan.pdf';

const ServiceOrb = ({ number, title, subtitle }) => {
  const isCompleted = number === 100;
  const pathColor = isCompleted ? "#4aa561" : "#E50019";
  const textColor = isCompleted ? "#4aa561": "#4c5630";
  return (
    <Col xs={6} md={4} lg={2} className="service--orb">
      <div className="service--title">
        <CircularProgressbarWithChildren
          className="circular-progress-bar"
          value={number}
          text={isCompleted ? null : `${number}%`}
          styles={buildStyles({
            pathColor,
            textColor,
            textSize: '2rem'
          })}
        >
          {isCompleted && (
            <div className="check">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.175 14.539">
                <path data-name="Path 928" d="m15.281.14 2.756 2.756a.481.481 0 0 1 0 .676l-8.041 8.04-.029.032L7.211 14.4a.49.49 0 0 1-.258.133H6.711l-.019-.009-.009-.005-.039-.023-.008-.006-.008-.006-.007-.007a.379.379 0 0 1-.058-.048l-2.755-2.755-.017-.018-.008-.009L.17 8.035a.48.48 0 0 1 0-.676l2.755-2.756a.479.479 0 0 1 .676 0l3.3 3.3L14.635.169a.479.479 0 0 1 .646-.029Z" fill="#178536" fill-rule="evenodd"/>
              </svg>
            </div>
          )}
        </CircularProgressbarWithChildren>
        <div className="service--text">
          <b>{title}</b>
          <span>{subtitle}</span>
        </div>
      </div>
    </Col>
  )
};

const FlipImage = ({ text, image }) => (
  <Col xs={6} lg={3}>
    <div className="flip-image">
      {image}
      <div className="flip-image--panel">
        <h3>Valle Del Río</h3>
        <p>
          {text}
        </p>
      </div>
    </div>
  </Col>
)

const commonProps = {
  layout: "fullWidth",
  quality: 100,
  transformOptions: {
    cropFocus: "center",
  },
  aspectRatio: 4/4,
}

const Services = () => {
  return (
    <div className="services  _section--padding" id="servicios">
      <Container>
        <h2 className="_section--title">
          SERVICIOS
        </h2>
        <Row className="services--orbs">
          <ServiceOrb number={100} title="ESPACIOS VERDES" subtitle="PREVISTOS" />
          <ServiceOrb number={100} title="AGUA POTABLE" subtitle="100% FINALIZADA" />
          <ServiceOrb number={30} title="RED DE CLOACAS" subtitle="EN PROCESO" />
          <ServiceOrb number={40} title="RED ELÉCTRICA" subtitle="EN PROCESO" />
          <ServiceOrb number={30} title="ALUMBRADO PÚBLICO" subtitle="EN PROCESO" />
          <ServiceOrb number={60} title="CÁMARAS DE SEGURIDAD" subtitle="EN PROCESO" />
        </Row>
        <section className="services--plans">
          <div>
            <a className="_section--text" href={masterplan} download>
              VER
              <b>MASTERPLAN</b>
            </a>
            <a className="_section--text" href={folleto} download>
              VER
              <b>FOLLETO</b>
            </a>
          </div>
          <p>
            El barrio cuenta con todos los servicios necesarios para que vivas tranquilo y seguro. Laguna artificial, sectores deportivos y de esparcimiento conforman este maravilloso ecosistema, ideal para disfrutar todos los días y un ingreso jerarquizado que tendrá a sus lados 5 locales comerciales.
          </p>
        </section>
        <div className="services--images">
          <Row nogutter>
            <FlipImage
              image={<StaticImage {...commonProps} src="../../../images/services/01.jpg" />}
              text="Está diseñado para convivir con la naturaleza y disfrutar de manera segura de sus plazas (con adaptaciones para niños), lagunas artificiales, decks y ciclovía."
            />
            <FlipImage
              image={<StaticImage {...commonProps} src="../../../images/services/02.jpg" />}
              text="Ofrece comodidad, seguridad y conveniencia a su familia. El barrio cuenta con un ingreso jerarquizado que será monitoreado permanentemente con cámaras de vigilancia."
            />
            <FlipImage
              image={<StaticImage {...commonProps} src="../../../images/services/03.jpg" />}
              text="Un lugar donde el aroma de la naturaleza no sólo se percibe en el ambiente, se siente en el cuerpo. Donde los espacios están pensados para que sientas todo el tiempo comodidad y segura libertad."
            />
            <FlipImage
              image={<StaticImage {...commonProps} src="../../../images/services/04.jpg" />}
              text="Ciclovía, lagunas artificiales, bosques autóctonos, plaza de niños, restaurantes, locales comerciales y complejo deportivo harán de cada día un día de descanso."
            />
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Services
