import React from "react";
import { Helmet } from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { Container, useScreenClass } from "react-grid-system";

import WhatsappMobileLogo from "Images/rrss_whatsapp.svg";

import Header from "./Header";
import Info from "./Info";
import Location from "./Location";
import Services from "./Services";
import Payments from "./Payments";
import Contact from "./Contact";
import Footer from "./Footer";

import "./styles.scss";

const WHATSAPP_NUMBER = "543571660571";

const commonProps = {
  layout: "fullWidth",
  quality: 100,
  transformOptions: {
    cropFocus: "center",
  },
};

const WhatsappBubble = () => (
  <div className="whatsapp-bubble">
    <a target="_blank" href={`https://wa.me/${WHATSAPP_NUMBER}`} rel="noreferrer">
      <WhatsappMobileLogo />
    </a>
  </div>
);

const Home = () => {
  const currentScreenClass = useScreenClass();
  const isMobile = ['xs'].includes(currentScreenClass);
  const topAspectRatio = 16/6;
  const bottomAspectRatio = 21/9;
  return (
    <main>
      <WhatsappBubble />
      <Helmet>
        <title>Loteo Valle del Río</title>
      </Helmet>
      <Header />
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
      >
        <StaticImage
          {...commonProps}
          aspectRatio={topAspectRatio}
          src="../../images/top-carousel/1.jpg"
        />
        <StaticImage
          {...commonProps}
          aspectRatio={topAspectRatio}
          src="../../images/top-carousel/2.jpg"
        />
        <StaticImage
          {...commonProps}
          aspectRatio={topAspectRatio}
          src="../../images/top-carousel/3.jpg"
        />
        <StaticImage
          {...commonProps}
          aspectRatio={topAspectRatio}
          src="../../images/top-carousel/4.jpg"
        />
      </Carousel>
      <Info />
      <Location />
      <Services />
      <Payments />
      <Container id="galeria">
        <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/01.jpg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/02.jpg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/03.jpg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/04.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/05.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/06.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/07.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/08.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/09.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/10.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/11.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/12.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/13.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/14.jpeg"
          />
          <StaticImage
            {...commonProps}
            aspectRatio={bottomAspectRatio}
            src="../../images/bottom-carousel/15.jpeg"
          />
        </Carousel>
      </Container>
      <Contact />
      <Footer />
    </main>
  );
};

export default Home;
